import { MenuSelector } from 'NewVersion/components/UI/Selector/MenuSelector'
import { AcreditacionsIcon } from 'NewVersion/icons/AcreditacionsIcon'
import { AgendaIcon } from 'NewVersion/icons/AgendaIcon'
import { CalendarIcon } from 'NewVersion/icons/CalendarIcon'
import { EntradaIcon } from 'NewVersion/icons/EntradaIcon'
import { FaqsIcon } from 'NewVersion/icons/FaqsIcon'
import { FastMeetingIcon } from 'NewVersion/icons/FastMeetingIcon'
import { FiraPasseigIconOutlined } from 'NewVersion/icons/FiraPasseigIconOutlined'
import { FolderIcon } from 'NewVersion/icons/FolderIcon'
import { HomeIcon } from 'NewVersion/icons/HomeIcon'
import { MessageIcon } from 'NewVersion/icons/MessageIcon'
import { MyMeetingsIcon } from 'NewVersion/icons/MyMeetingsIcon'
import { PropostesIcon } from 'NewVersion/icons/PropostesIcon'
import { UserIcon } from 'NewVersion/icons/UserIcon'
import 'NewVersion/styles/Sidebar.scss'
import * as path from 'Routes/Paths'
import { useEdition } from 'hooks/useEdition'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-use'
import { EditionSelector } from '../UI/Selector/EditionSelector'

const SidebarButton = ({ open, onClick }) => {
  return (
    <button className="sidebar-button" aria-label="Sidebar open/close icon" onClick={onClick}>
      {open ? (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2731_5698)">
            <path
              d="M44 24C44 12.96 35.04 4 24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24Z"
              fill="#5C5958"
            />
            <path d="M28 32L28 16L15.3684 24L28 32Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_2731_5698">
              <rect width="48" height="48" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4C12.96 4 4 12.96 4 24Z"
            fill="#5C5958"
          />
          <path d="M20 16L20 32L32.6316 24L20 16Z" fill="white" />
        </svg>
      )}
    </button>
  )
}

export const Sidebar = ({ onChangeEditionActions, isMobile, setMobileSidebarOpen }) => {
  const [open, setOpen] = useState(true)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { currentEdition, loading: loadingCurrentEdition } = useEdition()

  const isAuthenthicated = localStorage.isAuthenthicated

  const sidebarSections = [
    {
      key: 'gestio',
      items: [
        {
          key: 'acreditacions',
          type: 'button',
          icon: <AcreditacionsIcon />,
          path: path.REGISTER_ENTITY_PATH,
          dataTest: 'registerEntityTest',
          canShow: () => {
            return (
              !loadingCurrentEdition &&
              currentEdition &&
              (currentEdition.canCreateEnsPremsa() ||
                currentEdition.canCreateEnsProfessionals() ||
                currentEdition.canCreateEnsServeis())
            )
          },
        },
        {
          key: 'reunions_rapides',
          type: 'selector',
          icon: <MessageIcon />,
          dataTest: 'fastMeetingParentTest',
          items: [
            {
              icon: <FastMeetingIcon />,
              key: 'reservar_reunio_rapida',
              path: path.MEETINGS_PATH,
              canShow: () => {
                return (
                  !loadingCurrentEdition &&
                  currentEdition &&
                  currentEdition.canRegisterToQuickMeetings()
                )
              },
              dataTest: 'bookFastMeetingTest',
            },
            {
              icon: <MyMeetingsIcon />,
              key: 'meves_reunions',
              path: path.MY_MEETINGS_PATH,
              dataTest: 'myMeetingsTest',
            },
          ],
        },
        {
          key: 'agenda',
          type: 'button',
          icon: <AgendaIcon />,
          path: path.AGENDA_PATH,
          dataTest: 'agendabtnTest',
        },
        { key: 'entrades', type: 'button', icon: <EntradaIcon />, path: path.TIKETS_PATH },
      ],
    },
    {
      key: 'consulta',
      items: [
        {
          key: 'perfil',
          type: 'button',
          icon: <UserIcon />,
          path: path.ACCOUNT_PATH,
          dataTest: 'accountbtnTest',
        },
        {
          key: 'programacio',
          type: 'button',
          icon: <CalendarIcon />,
          path: path.PROGRAMMING_PATH,
          dataTest: 'schedulebtnTest',
        },
        {
          key: 'Professionals',
          type: 'button',
          icon: <AcreditacionsIcon />,
          path: path.PROFESSIONALS_PATH,
          dataTest: 'professionalsbtnTest',
        },
        {
          key: 'altres_edicions',
          type: 'edition_selector',
          icon: <FolderIcon fill={'#2B2A29'} />,
        },
      ],
    },
    {
      key: 'others',
      items: [
        {
          key: 'propostes_artistiques',
          type: 'button',
          icon: <PropostesIcon />,
          path: path.PROPOSALS_PATH,
        },
        {
          key: 'fira_passeig',
          type: 'button',
          icon: <FiraPasseigIconOutlined />,
          path: path.FIRAPASSEIG,
        },
        {
          key: 'faqs',
          type: 'button',
          icon: <FaqsIcon />,
          path: path.PREGUNTES_FREQUENTS,
        },
      ],
    },
  ]

  if (!isAuthenthicated) return <></>

  return (
    <div className={`${isMobile ? 'sidebar-mobile' : `sidebar ${!open ? 'close-sidebar' : ''}`} `}>
      <ul className="sidebar-sections" data-test="sidebar">
        <li
          className={`item ${pathname.includes(path.HOME_PATH) ? 'selected' : ''}`}
          onClick={() => {
            if (isMobile && setMobileSidebarOpen) {
              setMobileSidebarOpen(false)
            }
          }}
        >
          <Link
            to={path.HOME_PATH}
            aria-label={`Menu link go to ${t('Inici')}`}
            data-test={'goHomeTest'}
          >
            <HomeIcon />
            <span>{t('Inici')}</span>
          </Link>
        </li>
        {sidebarSections.map((section) => {
          return (
            <Fragment key={section.key}>
              <li className="section-title">
                <span>{t(section.key)}</span>
              </li>
              <li>
                <ul className="section-items">
                  {section.items.map((item) => {
                    if (item.canShow && !item.canShow()) return null
                    if (item.type === 'button') {
                      return (
                        <li
                          className={`item ${pathname.includes(item.path) ? 'selected' : ''}`}
                          key={item.key}
                          onClick={() => {
                            if (isMobile && setMobileSidebarOpen) {
                              setMobileSidebarOpen(false)
                            }
                          }}
                        >
                          <Link
                            to={item.path}
                            aria-label={`Menu link go to ${t(item.key)}`}
                            data-test={item.dataTest}
                          >
                            {item.icon}
                            <span>{t(item.key)}</span>
                          </Link>
                        </li>
                      )
                    } else if (item.key === 'altres_edicions') {
                      return (
                        <li
                          className={`item selector ${
                            pathname.includes(item.path) ? 'selected' : ''
                          }`}
                          key={item.key}
                          data-test={item.dataTest}
                        >
                          <EditionSelector label={t(item.key)} />
                        </li>
                      )
                    }
                    return (
                      <li
                        className={`item selector ${
                          pathname.includes(item.path) ? 'selected' : ''
                        }`}
                        key={item.key}
                        data-test={item.dataTest}
                      >
                        <MenuSelector
                          label={t(item.key)}
                          items={item.items.filter((item) => !item.canShow || item.canShow())}
                          icon={item.icon}
                          onClick={(item) => {
                            if (isMobile && setMobileSidebarOpen) {
                              setMobileSidebarOpen(false)
                            }
                            navigate(item.path)
                          }}
                        />
                      </li>
                    )
                  })}
                </ul>
              </li>
            </Fragment>
          )
        })}
      </ul>
      {!isMobile && <SidebarButton open={open} onClick={() => setOpen(!open)} />}
    </div>
  )
}
