import { Box } from '@mui/system'
import { ProfessionalEntityDialog } from 'NewVersion/components/Professional/ProfessionalEntityDialogComponent'
import { ProfessionalTable } from 'NewVersion/components/Professional/ProfessionalTable'
import { Filter, VIEW_TYPE_GRID, VIEW_TYPE_KEY } from 'NewVersion/components/UI/Filters/Filter'
import FilterSearch from 'NewVersion/components/UI/Filters/FilterSearch'
import { MAX_TIEMS_PAGE_DEFAULT, Pagination } from 'NewVersion/components/UI/Pagination/Pagination'
import 'NewVersion/styles/ProfessionalsListPage.scss'
import { useEdition } from 'hooks/useEdition'
import { useFavourites } from 'hooks/useFavourites'
import { useGuillotina } from 'hooks/useGuillotina'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { PROCEDENCIES_PROFESSIONAL } from 'utils/constants'
import { ENS_TYPES_OBJ } from 'utils/entityUtils'
import { getCurrentLanguage } from 'utils/utils'

function ProfessionalsListPage() {
  const { t } = useTranslation()
  const { edition, currentEdition } = useEdition()
  const [searchParams, setSearchParams] = useSearchParams()
  const [professionalEntityStack, setProfessionalEntityStack] = useState([])
  const currentLanguage = getCurrentLanguage()
  const { favouiteIds } = useFavourites()

  const getFiltersSearch = () => {
    let filterPerfil = ''
    let filterInterestArtistic = ''
    let filterProcedencia = ''
    let order = ''
    let filterFavourites = ''
    let search = ''

    if (searchParams.get('perfil') && searchParams.get('perfil') !== 'all') {
      const perfilArray = searchParams.get('perfil').split(',')
      if (perfilArray[0] === 'artista' || perfilArray[0] === 'representant') {
        filterPerfil = `&ens_tipus__or=${perfilArray
          .map((item) => `ens_tipus=${item}`)
          .join('%26')}`
      } else {
        filterPerfil = `&ens_type_name__or=${perfilArray
          .map((item) => `ens_type_name=${ENS_TYPES_OBJ[item].ens_type_name}`)
          .join('%26')}`
      }
    }

    if (searchParams.get('interes_artistic') && searchParams.get('interes_artistic') !== 'all') {
      const interes_artistic = searchParams.get('interes_artistic').split(',')
      filterInterestArtistic = `&interes_artistic__in=${interes_artistic}`
    }

    if (searchParams.get('procedencia') && searchParams.get('procedencia') !== 'all') {
      const procedenciaArray = searchParams.get('procedencia').split(',')
      filterProcedencia = `&procedencia__or=${procedenciaArray
        .map((item) => `procedencia=${item}`)
        .join('%26')}`
    }

    if (searchParams.get('search') && searchParams.get('search') !== '') {
      search = `&__or=${['ens_title', 'title']
        .map((item) => `${item}=${searchParams.get('search')}`)
        .join('%26')}`
    }

    if (searchParams.get('favourites') && searchParams.get('favourites') !== 'all') {
      if (searchParams.get('favourites') === 'yes') {
        filterFavourites = favouiteIds
          ? `&uuid__or=${favouiteIds.map((item) => `uuid=${item}`).join('%26')}`
          : ''
      } else if (searchParams.get('favourites') === 'no') {
        filterFavourites = favouiteIds
          ? `&uuid__and=${favouiteIds.map((item) => `uuid__not=${item}`).join('%26')}`
          : ''
      }
    }

    if (searchParams.get('sort-by') && searchParams.get('sort-direction')) {
      order = `&_sort_${searchParams.get('sort-direction')}=${searchParams.get('sort-by')}`
    }

    return `${filterPerfil}${filterInterestArtistic}${filterProcedencia}${filterFavourites}${search}${order}`
  }

  const { data: { items_total: itemsTotal } = 0 } = useGuillotina({
    path: `${edition}/@search?type_name__in=AcreditacioProfessional,AcreditacioPremsa&b_start=0&b_size=1`,
  })

  const getPaginationFilter = () => {
    let paginationFilter = `&b_size=${MAX_TIEMS_PAGE_DEFAULT}`
    if (searchParams.get('page')) {
      paginationFilter = `&b_start=${
        (parseInt(searchParams.get('page')) - 1) * MAX_TIEMS_PAGE_DEFAULT
      }${paginationFilter}`
    } else paginationFilter = `&b_start=0${paginationFilter}`
    console.log(paginationFilter)
    return paginationFilter
  }

  const { isLoading, data: { items: professionals, items_total: showingItems } = [] } =
    useGuillotina({
      path: `${edition}/@search?type_name__in=AcreditacioProfessional,AcreditacioPremsa${getFiltersSearch()}${getPaginationFilter()}`,
    })

  const { data: { items: vocabularyAmbits } = [] } = useGuillotina({
    path: `${edition}/@vocabularies/interes_artistic`,
    useStatic: true,
  })

  const filtersDefiniton = useMemo(() => {
    const filters = [
      {
        key: 'perfil',
        label: t('perfil_professional'),
        sorted: true,
        data: [
          { value: 'all', label: t('Veure’ls tots'), default: true },
          { value: 'artista', label: t('artista') },
          { value: 'representant', label: t('representant') },
          { value: 'professional', label: t('professional') },
          { value: 'premsa', label: t('premsa') },
        ],
      },
    ]
    if (vocabularyAmbits) {
      filters.push({
        key: 'interes_artistic',
        label: t('Àmbit'),
        sorted: true,
        data: [
          { value: 'all', label: t('show_all'), default: true },
          ...vocabularyAmbits.map((ambit) => ({
            value: ambit.token,
            label: t(ambit.title),
          })),
        ],
      })
    }

    if (vocabularyAmbits) {
      filters.push({
        key: 'procedencia',
        label: t('Procedència'),
        sorted: true,
        data: [
          { value: 'all', label: t('Veure-les totes'), default: true },
          ...PROCEDENCIES_PROFESSIONAL.map((procedence) => ({
            value: procedence,
            label: t(procedence),
          })),
        ],
      })
    }
    filters.push({
      key: 'favourites',
      label: t('favourites'),
      sorted: true,
      unique: true,
      data: [
        { value: 'all', label: t('Veure’ls tots'), default: true },
        { value: 'yes', label: t('si') },
        { value: 'no', label: t('no') },
      ],
    })
    return filters
  }, [currentEdition, vocabularyAmbits, currentLanguage])

  return (
    <Box className={'professionals-list-page-container page-wrapper'}>
      <Box display={'flex'} alignItems={'end'} justifyContent={'space-between'} flexWrap={'wrap'}>
        <Box maxWidth={'500px'}>
          <h1>{t('Professionals')}</h1>
          <p>{t('professionals_page_detail', { edition: edition })}</p>
        </Box>
        <FilterSearch
          placeholder={t('professionals_search')}
          onChange={({ target }) => {
            searchParams.set('search', target.value)
            searchParams.set('page', 1)
            setSearchParams(searchParams)
          }}
          dataTest="inputSearchScheduleTest"
          value={searchParams.get('search') || ''}
          variant={'outlined'}
        />
      </Box>
      <Filter
        itemsTotal={itemsTotal}
        showingItems={showingItems || 0}
        filtersDefiniton={filtersDefiniton}
      />
      <ProfessionalTable
        key={`professionalTable_${getFiltersSearch()}${getPaginationFilter()}`}
        professionals={professionals}
        loading={isLoading}
        onClick={(professional) => {
          setProfessionalEntityStack([...professionalEntityStack, professional])
        }}
        gridView={searchParams.get(VIEW_TYPE_KEY) === VIEW_TYPE_GRID}
      />
      {!isLoading && <Pagination itemsTotal={showingItems} />}
      <ProfessionalEntityDialog
        uuid={professionalEntityStack.length > 0 ? professionalEntityStack.at(-1) : null}
        onClose={() => setProfessionalEntityStack([])}
        onClick={(uuid) => setProfessionalEntityStack([...professionalEntityStack, uuid])}
        onBack={() => {
          const newProfessionalEntityStack = [...professionalEntityStack]
          newProfessionalEntityStack.splice(-1)
          setProfessionalEntityStack(newProfessionalEntityStack)
        }}
      />
    </Box>
  )
}

export default ProfessionalsListPage
