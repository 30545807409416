import 'NewVersion/styles/FiraPasseig.scss'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useEdition } from 'hooks/useEdition'
import Button from 'NewVersion/components/UI/Buttons/Button'
import { FiraPasseigCalendarIcon } from 'NewVersion/icons/FiraPasseigCalendarIcon'
import { CREATE_ENTITY_PATH } from 'Routes/Paths'
import { Link } from 'react-router-dom'

function FiraPasseigPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentEdition } = useEdition()

  return (
    <div className="firaPasseigPage">
      <h1>{t('FiraPasseig')}</h1>
      <h4>{t('UBICACIO I HORARIS 2024')}</h4>
      <ul>
        <li>
          El <strong>FiraPasseig</strong> està ubicat al <strong>Passeig Pere III</strong> de
          Manresa
        </li>
        <li>
          <strong>Activitat: 11, 12 i 13</strong> d&apos;octubre de 2024
        </li>
        <li>
          <strong>Horari:</strong> 11h a 22h
        </li>
        <li>
          <strong>Muntatge:</strong> Divendres 11 d&apos;octubre de 9h a 17h
        </li>
        <li>
          <strong>Desmuntatge:</strong> Diumenge 13 d&apos;octubre de 20h a 22h
        </li>
      </ul>
      <div className="firaLink">
        <p>Per a més detalls, consulta tota la informació a: </p>
        <Link to={'https://firamediterrania.cat/la-fira/artesans-i-gastronomia'}>
          https://firamediterrania.cat/la-fira/artesans-i-gastronomia
        </Link>
      </div>

      <Button
        color="primary outlined"
        type="submit"
        dataTest="btnAddServicesEntityTest"
        icon={<FiraPasseigCalendarIcon />}
        onClick={() => navigate(`${CREATE_ENTITY_PATH}/serveis`)}
        disabled={!currentEdition.canCreateEnsServeis()}
      >
        {t('solicitud-firapasseig')}
      </Button>
    </div>
  )
}

export default FiraPasseigPage
