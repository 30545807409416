import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { swrOptions, swrOptionsStatic } from 'NewVersion/utils/swrOptions'
import useSWR from 'swr'
import { getCurrentJWToken } from 'utils/utils'
// import { useAuth } from './useAuth'

export function useGuillotina({ path, useStatic = false, getFile = false, guillotinadb = false }) {
  const token = getCurrentJWToken()

  const { data, error, mutate, isLoading } = useSWR(
    path ? path : null,
    (url) => {
      return fetchGuillotina({
        path: url,
        checkError: true,
        getFile: getFile,
        token: token,
        guillotinadb: guillotinadb,
      })
    },
    useStatic ? swrOptionsStatic : swrOptions
  )

  return {
    data,
    isLoading,
    error,
    refresh: mutate,
  }
}
