import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { getCurrentJWToken, getEmailFromToken } from 'utils/utils'
import { useGuillotina } from './useGuillotina'

export function useFavourites() {
  const token = getCurrentJWToken()
  const email = getEmailFromToken()

  const { data, refresh, isLoading } = useGuillotina({ path: email, guillotinadb: true })

  const addFavourite = (uuid, isArray = false) => {
    fetchGuillotina({
      path: email,
      method: 'PATCH',
      data: {
        preferits: isArray ? [...data.preferits, ...uuid] : [...data.preferits, uuid],
      },
      token: token,
      guillotinadb: true,
    }).then(() => {
      refresh()
    })
  }

  const removeFavourite = (uuid, isArray = false) => {
    fetchGuillotina({
      path: email,
      method: 'PATCH',
      data: {
        preferits: data.preferits.filter((item) =>
          isArray ? !uuid.includes(item) : item !== uuid
        ),
      },
      token: token,
      guillotinadb: true,
    }).then(() => {
      refresh()
    })
  }

  return {
    favouiteIds: data && data.preferits ? data.preferits : [],
    loading: isLoading,
    isFavouritesLoading: isLoading,
    addFavourite,
    removeFavourite,
  }
}
