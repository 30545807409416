import React from 'react'

import { InputCheckbox } from 'Components/Forms/FormElements'
import { useTranslation } from 'react-i18next'
import { AFIRMATIVE_VALUE, NEGATIVE_VALUE } from 'utils/constants'
import { getOptionsFormat } from 'utils/formUtils'
import { get } from 'utils/objectUtils'
import { FileInputField } from './InputFile'
import { SelectInputField } from './InputSelect'
import { TextInputField } from './InputText'
import { VocabularyDynamicSelectInputField } from './VocabularyDynamicInputSelect'

export const EditComponent = React.forwardRef(
  (
    {
      schema,
      value,
      name,
      onChange,
      onBlur,
      error,
      disabled,
      dataTest,
      label,
      description,
      required,
      path,
    },
    ref
  ) => {
    const getCommonProps = () => {
      return {
        value,
        onChange,
        onBlur,
        error,
        disabled,
        dataTest,
        label,
        name,
        description,
        required,
        ref,
      }
    }

    const { t } = useTranslation()
    if (schema?.widget === 'textarea' || schema?.widget === 'richtext') {
      return (
        <TextInputField
          {...getCommonProps()}
          onChange={(ev) => {
            onChange(ev.target.value)
          }}
          type="text"
          multiline
        />
      )
    } else if (schema?.widget === 'checkbox_selector') {
      return (
        <SelectInputField
          {...getCommonProps()}
          options={getOptionsFormat([AFIRMATIVE_VALUE, NEGATIVE_VALUE], t, name)}
          value={value === true ? AFIRMATIVE_VALUE : value === false ? NEGATIVE_VALUE : value}
          onChange={(_name, event) => {
            console.log(event.target.value)
            onChange(event.target.value)
          }}
        />
      )
    } else if (schema?.type === 'boolean') {
      return (
        <InputCheckbox
          {...getCommonProps()}
          checked={value ? 'checked' : ''}
          onChange={(ev) => {
            onChange(ev.target.checked)
          }}
        />
      )
    } else if (schema?.widget === 'vocabularyDynamic') {
      return (
        <VocabularyDynamicSelectInputField
          {...getCommonProps()}
          isSearchable
          onChange={(_name, event) => {
            onChange(event.target.value)
          }}
          vocabularyName={schema.vocabularyName}
        />
      )
    } else if (
      schema?.type === 'array' &&
      get(schema, 'items.widget', null) === 'vocabularyDynamic'
    ) {
      return (
        <VocabularyDynamicSelectInputField
          {...getCommonProps()}
          isMulti
          isSearchable
          onChange={(_name, event) => {
            onChange(event.target)
          }}
          vocabularyName={schema.items.vocabularyName}
        />
      )
    } else if (schema?.type === 'array' && get(schema, 'items.enum', null) !== null) {
      return (
        <SelectInputField
          {...getCommonProps()}
          isMulti
          isSearchable
          options={getOptionsFormat(get(schema, 'items.enum', []), t, name)}
          onClear={() => {
            onChange([])
          }}
          onChange={(_name, event) => {
            onChange(event.target.value)
          }}
        />
      )
    } else if (schema?.type === 'string' && get(schema, 'enum', null) !== null) {
      return (
        <SelectInputField
          {...getCommonProps()}
          isSearchable
          options={getOptionsFormat(get(schema, 'enum', []), t, name)}
          onClear={() => {
            onChange('')
          }}
          onChange={(_name, event) => {
            onChange(event.target.value)
          }}
        />
      )
    } else if (schema?.widget === 'file') {
      return (
        <FileInputField
          {...getCommonProps()}
          path={path}
          canDownload={!!path && !!value}
          onChange={(e) => {
            const fitxer = e.target.files[0]
            onChange(fitxer)
          }}
          onDelete={() => {
            onChange(null)
          }}
        />
      )
    }

    const getInputType = () => {
      switch (schema?.type) {
        case 'integer':
          return 'number'
        case 'datetime':
          return 'datetime-local'
        default:
          return 'text'
      }
    }
    return (
      <TextInputField
        {...getCommonProps()}
        onChange={(ev) => {
          onChange(ev.target.value)
        }}
        type={getInputType()}
      />
    )
  }
)

EditComponent.displayName = 'EditComponent'
export default EditComponent
