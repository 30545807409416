import { AuthContext } from 'App'
import WrapperUnauthAction from 'Components/WrapperUnauthAction'
import { TextInputField } from 'NewVersion/components/UI/Forms/TextInputField'
import React, { useEffect, useState } from 'react'
import { getEmailFromToken } from 'utils/utils'

import { LoadingComponent } from 'Components/HelperComponents'
import Button from 'NewVersion/components/UI/Buttons/Button'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { useEdition } from 'hooks/useEdition'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import 'styles/Login.scss'

function NewPasswordPage() {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const [password, passwordChange] = useState('')
  const [password2, password2Change] = useState('')
  const [pwdError, setError] = useState({
    haveError: false,
    message: '',
  })
  const [newPasswordToken, setnewPasswordToken] = useState(false)
  const [isLoading, setLoader] = useState(false)
  const navigate = useNavigate()
  const { setLastEdition } = useEdition()

  useEffect(() => {
    if (searchParams && searchParams.get('token')) {
      setnewPasswordToken(searchParams.get('token'))
    } else {
      navigate('/')
    }
  }, [searchParams])

  const { dispatch } = React.useContext(AuthContext)
  const validateForm = (e) => {
    return false
  }

  const onNewPassword = async (data, e) => {
    data.preventDefault()
    // data.preventDefault()
    setError(false)
    if (password !== password2) {
      setError({
        haveError: true,
        message: t('passwords_match'),
      })
      return false
    } else {
      setError({
        haveError: false,
        message: false,
      })
    }

    setLoader(true)
    await fetchGuillotina({
      path: `@validate/${newPasswordToken}`,
      method: 'POST',
      checkError: true,
      unAuth: true,
      data: { password: password },
    })
      .then(function (response) {
        if (!response.token) {
          navigate('/')
        }
        localStorage.setItem('token', response.token)
        localStorage.setItem('isAuthenthicated', true)
        passwordChange('')
        const token_val = response.token
        const obj_pay = {
          user: getEmailFromToken(token_val),
          token: token_val,
        }
        dispatch({
          type: 'LOGIN',
          payload: obj_pay,
        })

        setLastEdition()
        setLoader(false)

        if (localStorage.reRoute !== 'login') {
          const rout = localStorage.reRoute
          localStorage.removeItem('reRoute')
          navigate(rout)
        } else {
          navigate('/')
        }
      })
      .catch(function () {
        setError({
          haveError: true,
          message: t('register_error'),
        })
        setLoader(false)
        passwordChange('')
        password2Change('')
      })
  }

  return (
    <WrapperUnauthAction>
      <section className="login">
        <div className="input-container">
          <h2 className="input-title">{t('Benvinguda')}</h2>
          <form className="inputs" onSubmit={onNewPassword} data-test="resetPasswTest">
            <TextInputField
              type="password"
              name="password"
              label={t('password')}
              onChange={({ target }) => passwordChange(target.value)}
              dataTest="inpuPasswordTest"
              value={password}
              aria-label="Password"
            />
            <TextInputField
              type="password"
              label={t('password_repeat')}
              name="passwordRepeat"
              onChange={({ target }) => password2Change(target.value)}
              dataTest="inpuPasswordRepeatTest"
              value={password2}
              aria-label={t('password_repeat')}
              error={pwdError}
            />
            {pwdError && <p className="error">{pwdError.message}</p>}
            {!isLoading ? (
              <Button
                disabled={validateForm()}
                color="primary"
                type="submit"
                aria-label="Login button"
              >
                {t('new_password')}
              </Button>
            ) : (
              <div style={{ height: '56px' }}>
                <LoadingComponent noClass={true} />
              </div>
            )}
          </form>
        </div>
        <div className="login-image" />
      </section>
    </WrapperUnauthAction>
  )
}

export default NewPasswordPage
