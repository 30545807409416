import { TextField } from '@mui/material'
import 'NewVersion/styles/UI/TextInputField.scss'
import { Fragment } from 'react'

export const TextInputField = ({
  label = '',
  onChange,
  dataTest,
  name,
  type,
  error = false,
  required,
  description,
  onBlur,
  value,
  disabled,
  placeholder = null,
  multiline = false,
  helperText,
}) => {
  console.log('error', error)
  return (
    <TextField
      label={
        <Fragment>
          {label}
          <span className="description">{description}</span>
        </Fragment>
      }
      placeholder={placeholder}
      InputLabelProps={{ style: { fontSize: '16px' } }}
      InputProps={{
        inputProps: {
          'data-test': dataTest,
        },
      }}
      variant="standard"
      className={`text-input ${multiline ? 'text-area' : ''} ${error ? 'error' : ''}`}
      onChange={onChange}
      name={name}
      type={type}
      error={error}
      required={required}
      description={description}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
      multiline={multiline}
      rows={multiline ? 3 : 0}
      maxRows={5}
      fullWidth
      helperText={helperText}
    />
  )
}
