import { requiredError } from 'Components/Forms/FormErrors'
import { AlertComponent } from 'Components/HelperComponents/AlertComponent'
import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { EditComponent } from 'NewVersion/components/Fields/EditComponent'
import { SelectInputField } from 'NewVersion/components/Fields/InputSelect'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { useFormik } from 'formik'
import { useEdition } from 'hooks/useEdition'
import { useGuillotina } from 'hooks/useGuillotina'
import React, { Fragment, useMemo } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { get } from 'utils/objectUtils'
import { copyGuillotinaObjectWithoutIds, getCurrentJWToken, getEmailFromToken } from 'utils/utils'
import Button from '../UI/Buttons/Button'

const FormContent = ({ setRepresentantObj, ensTypeObj, toggleInit, showDetailObj }) => {
  const { edition, currentEdition, loading } = useEdition()

  const {
    data: entitySchemaData,
    isLoading: entitySchemaLoading,
    error: entitySchemaError,
  } = useGuillotina({
    path: `@types/${ensTypeObj['interface']}`,
  })

  const {
    data: optionsRepresentantResult,
    isLoading: optionsRepresentantLoading,
    error: optionsRepresentantError,
  } = useGuillotina({
    path: `@search?type_name=Ens&tipus=representant&owners=${getEmailFromToken()}&_sort_des=creation_date`,
  })

  const optionsRepresentant = useMemo(() => {
    const getResult = (list) => {
      return list.map((ens) => {
        return {
          label: ens.title + ' (' + ens.year + ')',
          value: ens.path,
          tipus: ens.tipus,
          year: ens.year,
        }
      })
    }
    if (optionsRepresentantResult) {
      return getResult(optionsRepresentantResult.items)
    }
  }, [optionsRepresentantResult])

  if (loading || entitySchemaLoading || optionsRepresentantLoading) {
    return <LoadingComponent />
  }

  const setRepresentantManagerForm = async (representant) => {
    const representantValue = representant.value || representant.target.value
    const representantYear = representant.year || representantValue.split('/')[1]
    const token = getCurrentJWToken()
    await fetchGuillotina({
      path: representantValue,
      method: 'GET',
      token,
    })
      .then(async (response) => {
        if (representantYear !== edition) {
          const newRepresentant = copyGuillotinaObjectWithoutIds(response)
          await fetchGuillotina({
            path: `${edition}`,
            method: 'POST',
            data: newRepresentant,
            token: token,
          }).then(async (response) => {
            if (response) {
              const path = '/' + response['@id'].split('/').slice(-2).join('/')
              await setRepresentantManagerForm({ value: path, year: edition })
            }
          })
        } else {
          setRepresentantObj(representantValue, response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <ManagerFormContent
      ensTypeObj={ensTypeObj}
      edition={edition}
      toggleInit={toggleInit}
      currentEdition={currentEdition}
      schema={{
        data: entitySchemaData,
        error: entitySchemaError,
        loading: entitySchemaLoading,
      }}
      optionsRepresentant={{
        data: optionsRepresentant,
        error: optionsRepresentantError,
        loading: optionsRepresentantLoading,
      }}
      setRepresentantManagerForm={setRepresentantManagerForm}
      setRepresentantObjManagerForm={setRepresentantObj}
      showDetailObj={showDetailObj}
    />
  )
}

const ManagerFormContent = ({
  ensTypeObj,
  edition,
  toggleInit,
  schema,
  optionsRepresentant,
  setRepresentantManagerForm,
  readOnlyForm = false,
  showDetailObj,
}) => {
  const { t } = useTranslation()
  const [submitError, setSubmitError] = React.useState({
    hasError: false,
    message: '',
  })

  const handleFormError = (error) => {
    if (error.response && error.response.status === 412) {
      if (get(error, 'response.data.code') === 'already-exists') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('title_already_exists')}`,
        })
      } else if (get(error, 'response.data.code') === 'invalid-email') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('invalid_email')}`,
        })
      } else if (get(error, 'response.data.code') === 'promo-code-not-exist') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('promo_code_not_exist')}`,
        })
      } else {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('error_create_or_update_ens')}`,
        })
      }
    } else {
      setSubmitError({
        hasError: true,
        message: `Error: ${t('error_generic')}`,
      })
    }
    window.scrollTo(0, 0)
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    const valuesEntity = {}
    const token = getCurrentJWToken()
    Object.keys(values).forEach((valueKey) => {
      if (valueKey.indexOf('entity_') === 0) {
        valuesEntity[valueKey.replace('entity_', '')] = values[valueKey] || null
      }
    })

    const data = Object.assign({}, valuesEntity, {
      '@type': ensTypeObj['interface'],
      tipus: 'representant',
      tarifa: 'gratuita',
    })

    if (showDetailObj) {
      await fetchGuillotina({
        path: `${edition}`,
        method: 'PATCH',
        data: data,
        token: token,
      })
        .then(() => {
          toggleInit()
        })
        .catch((error) => {
          handleFormError(error)
          setSubmitting(false)
          return
        })
        .finally(() => {
          setSubmitting(false)
        })
    } else {
      await fetchGuillotina({
        path: `${edition}`,
        method: 'POST',
        data: data,
        token: token,
      })
        .then(async (response) => {
          if (response) {
            const path = '/' + response['@id'].split('/').slice(-2).join('/')
            await setRepresentantManagerForm({ value: path, year: edition })
          }
          setSubmitting(false)
        })
        .catch((error) => {
          handleFormError(error)
          setSubmitting(false)
          return
        })
    }
  }

  const getInitialValues = React.useMemo(() => {
    const result = {}
    ensTypeObj['requiredFields'].forEach((item) => {
      result[`entity_${item}`] = showDetailObj ? showDetailObj.item : undefined
    })
    return result
  }, [ensTypeObj])

  const formik = useFormik({
    initialValues: getInitialValues,
    validateOnChange: false,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {}
      ensTypeObj['requiredFields'].forEach((key) => {
        if (key !== 'declaracio_acceptar') {
          const keyForm = `entity_${key}`
          if ((!(keyForm in values) || !values[keyForm]) && renderLocationFields(key)) {
            errors[keyForm] = requiredError()
          }
        }
      })

      const keyForm = `entity_correu`
      if (values[keyForm] && !values[keyForm].match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
        errors[keyForm] = t('invalid_email')
      }
      return errors
    },
  })

  const fieldsWithPrefixLabel = ['idioma', 'correu']

  const getLabel = (key) => {
    if (fieldsWithPrefixLabel.includes(key)) return `ens_${key}`
    return key
  }

  const renderLocationFields = (key) => {
    if (key === 'comunitat_autonoma' && get(formik, 'values.entity_pais', '') !== 'country-ES') {
      return false
    }
    if (
      key === 'comarca' &&
      (get(formik, 'values.entity_pais', '') !== 'country-ES' ||
        get(formik, 'values.entity_comunitat_autonoma', '') !== 'catalunya')
    ) {
      return false
    }
    return true
  }
  const renderFields = (key) => {
    if (key === 'declaracio_acceptar' || key === 'representant') return false
    return renderLocationFields(key)
  }

  const getDescription = (value, key) => {
    /* TODO: Refactor de les descripcions dels camps, evitar casos unics i utilitzar el type_name per crear les claus,
    es necessari modificar back per retornar la clau en el camp descripcio per exemple, analitzar-ho bé. */

    if (key === 'correu') {
      return t('ens_correu_description')
    }
    if (key === 'pais') {
      return t('pais_multiple_description')
    }
    return value.description ? t(`${key}_description`) : null
  }

  return (
    <>
      {submitError.hasError && submitError.message && (
        <AlertComponent type="danger"> {submitError.message}</AlertComponent>
      )}

      <h4>
        {t('presentar_proposta_manager')} {edition}
      </h4>

      {!optionsRepresentant.loading && optionsRepresentant.data?.length > 0 && (
        <form className="Form" id="selector-representant">
          <h5>{t('escull_manager')}</h5>
          <SelectInputField
            name="representant"
            label={t('Seleccioneu una opcio')}
            onChange={(ev) => {
              formik.setFieldValue('managerSelector', ev)
              formik.validateForm()
            }}
            onBlur={() => {
              formik.setFieldTouched('managerSelector')
            }}
            options={optionsRepresentant.data}
            value={formik.values['managerSelector']}
            error={formik.touched['managerSelector'] && formik.errors['managerSelector']}
            callback={setRepresentantManagerForm}
            dataTest={'managerSelector'}
          />
        </form>
      )}

      <form
        className="Form"
        onSubmit={(values) => formik.handleSubmit(values)}
        data-test="formTest"
      >
        {schema &&
          schema.data &&
          !schema.loading &&
          ensTypeObj['orderFieldsForm']
            .filter((item) => item.title !== 'declaracio_fieldset')
            .map((item) => {
              return (
                <Fragment key={item['title']}>
                  {item['title'] !== 'entity_data' ? (
                    <h5>{t(item['title'])}&nbsp;</h5>
                  ) : (
                    <h4 className="centre-line">
                      {t('o bé')} {t("crea'n un de nou")}
                    </h4>
                  )}
                  <div className="fragment">
                    {item['fields'].map((key) => {
                      if (renderFields(key) && get(schema, `data.properties[${key}]`, null)) {
                        const value = schema.data.properties[key]
                        const keyForm = `entity_${key}`
                        return (
                          <EditComponent
                            key={keyForm}
                            name={keyForm}
                            label={
                              key === 'title'
                                ? t(getLabel(ensTypeObj['labelName']))
                                : t(getLabel(key))
                            }
                            schema={value}
                            description={getDescription(value, key)}
                            value={formik.values[keyForm]}
                            required={(ensTypeObj['requiredFields'] ?? []).includes(key)}
                            onBlur={() => {
                              formik.setFieldTouched(keyForm)
                            }}
                            onChange={(ev) => {
                              formik.setFieldValue(keyForm, ev)
                              formik.validateForm()
                            }}
                            error={formik.touched[keyForm] && formik.errors[keyForm]}
                            dataTest={`inputFieldTest_${keyForm}`}
                          />
                        )
                      }
                    })}
                  </div>
                </Fragment>
              )
            })}

        <div>
          {formik.isSubmitting && <LoadingComponent />}
          {!readOnlyForm && (
            <div className="footer-buttons">
              <Button
                type="button"
                dataTest="btnCancelFormTest"
                disabled={formik.isSubmitting}
                onClick={() => toggleInit()}
                color="primaryOutlinedIcon outlined"
              >
                {t('cancel')}
              </Button>
              <Button
                style={{ width: '130px' }}
                type="submit"
                dataTest="btnSubmitFormTest"
                disabled={formik.isSubmitting}
              >
                {t('next')}
              </Button>
            </div>
          )}
        </div>
      </form>
    </>
  )
}

export const ManagerForm = withTranslation()(FormContent)
