export const arrayLanguagesOptions = [
  { label: 'Català', value: 'lang-ca' },
  { label: 'Español', value: 'lang-es' },
  { label: 'English', value: 'lang-en' },
]

export const getOptionFromValue = (items, value) => {
  return (items && value && items.find((o) => o.value === value)) || undefined
}
export const getOptionsFromValue = (items, value) => {
  return (items && value && items.filter((o) => value.includes(o.value))) || []
}
export const getOptionsFormat = (items, t, name) => {
  let options = []
  items.forEach((item) => {
    options.push({ label: t(item), value: item })
  })
  if (name.indexOf('idioma') !== -1) {
    options = arrayLanguagesOptions
  }
  return options
}

export const getValuesOfReactSelectValues = (data) => {
  const arrayOptions = []
  if (data && data.length !== 0) {
    data.forEach((v) => {
      arrayOptions.push(v.value)
    })
  }
  return arrayOptions
}
