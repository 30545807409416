import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { AccreditationPersonDetailInfo } from 'NewVersion/components/Show/AccreditationPersonDetailInfo'
import { EmailIcon } from 'NewVersion/icons/EmailIcon'
import { FacebookIcon } from 'NewVersion/icons/FacebookIcon'
import { FavouriteIcon } from 'NewVersion/icons/FavouriteIcon'
import { InstagramIcon } from 'NewVersion/icons/InstagramIcon'
import { LinkIcon } from 'NewVersion/icons/LinkIcon'
import { TrashIcon } from 'NewVersion/icons/TrashIcon'
import { TwitterIcon } from 'NewVersion/icons/TwitterIcon'
import { capitalizeFirstLetter } from 'NewVersion/utils/text'
import { useFavourites } from 'hooks/useFavourites'
import { useGuillotina } from 'hooks/useGuillotina'
import { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Chips from '../UI/Chips/Chips'

export function MainShow({ showData, artist }) {
  const { favouiteIds, addFavourite, removeFavourite, isFavouritesLoading } = useFavourites()
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [accreditationPerson, setAccreditationPerson] = useState(null)

  const { data: artistData, isLoading: artistLoading } = useGuillotina({
    path: showData ? artist : null,
  })

  const { data: artistAccreditations, isLoading: artistAccreditationsLoading } = useGuillotina({
    path: showData
      ? `${artist}/@search?type_name__in=AcreditacioProfessional,AcreditacioPremsa`
      : null,
  })

  const { data: manager, isLoading: managerLoading } = useGuillotina({
    path: artistData ? artistData.representant : null,
  })

  const { data: managerAccreditations, isLoading: managerAccreditationsLoading } = useGuillotina({
    path: manager
      ? `${artistData.representant}/@search?type_name__in=AcreditacioProfessional,AcreditacioPremsa`
      : null,
  })

  const canRenderContactData = useMemo(() => {
    return !(
      !manager &&
      (!artistAccreditations || artistAccreditations.items_total === 0) &&
      (!managerAccreditations || managerAccreditations.items_total === 0)
    )
  }, [manager, artistAccreditations, managerAccreditations])

  const transformTextMayus = (text) => {
    const result = []
    const arrayText = text.split(' ')
    arrayText.map((arrayTextEl) => {
      const textLower = arrayTextEl.toLowerCase()
      const textCapitalized = capitalizeFirstLetter(textLower)
      result.push(textCapitalized)
    })
    return result.join(' ')
  }

  if (
    artistLoading ||
    artistAccreditationsLoading ||
    managerLoading ||
    managerAccreditationsLoading
  )
    return <LoadingComponent />

  const renderScheduleButton = (entity) => {
    const isFavourite = favouiteIds && favouiteIds.includes(entity['@uid'])
    if (isFavourite) {
      return (
        <Chips
          text={t('delete')}
          icon={<TrashIcon />}
          dataTest={'deleteCalendar'}
          tooltip={t('remove_to_favourite')}
          disabled={isFavouritesLoading}
          onClick={(ev) => {
            ev.preventDefault()
            ev.stopPropagation()
            removeFavourite(entity['@uid'])
          }}
        />
      )
    }
    return (
      <Chips
        text={t('afegir')}
        icon={<FavouriteIcon outline={true} />}
        dataTest={'addCalendar'}
        tooltip={t('add_to_favourite')}
        disabled={isFavouritesLoading}
        onClick={(ev) => {
          ev.preventDefault()
          ev.stopPropagation()
          addFavourite(entity['@uid'])
        }}
      />
    )
  }

  const renderAccreditation = (accreditation) => {
    return (
      <div
        className="accreditation"
        onClick={() => {
          setIsModalOpen(true)
          setAccreditationPerson(accreditation)
        }}
      >
        <b>{transformTextMayus(accreditation?.title ?? '--')}</b>
        {renderScheduleButton(accreditation)}
      </div>
    )
  }

  return (
    <div className="mainShowBox">
      {canRenderContactData ? (
        <>
          <AccreditationPersonDetailInfo
            person={accreditationPerson}
            setPersonNull={() => setAccreditationPerson(null)}
            onClose={() => setIsModalOpen(false)}
            isOpen={isModalOpen}
          />
          {manager && (
            <div className="manager">
              <h4>{t('representant')}</h4>
              <div className="managerBox">
                <div className="managerBoxHeader">
                  <h4>{manager?.title}</h4>
                  <div>
                    <a href={manager?.web}>
                      <LinkIcon />
                    </a>
                    <a href={manager?.email}>
                      <EmailIcon />
                    </a>
                  </div>
                </div>

                <div className="managerBoxContent">
                  <div>
                    <b>{t('Adreca')}</b>
                    <p>{manager?.adreca}</p>
                  </div>
                  <div className="telAndEmail">
                    <div>
                      <b>{t('telefon')}</b>
                      <p>{manager?.telefon}</p>
                    </div>
                    {manager?.email && (
                      <div>
                        <b>{t('Correu electrònic')}</b>
                        <p style={{ textDecoration: 'underline' }}>{manager?.email}</p>
                      </div>
                    )}
                  </div>
                  {manager?.web && (
                    <div>
                      <b>{t('web')}</b>
                      <p>{manager?.web}</p>
                    </div>
                  )}
                </div>

                <div className="socialMedia">
                  {manager.facebook && (
                    <a href={manager.facebook}>
                      <FacebookIcon color="#5C5958" />
                    </a>
                  )}
                  {manager.instagram && (
                    <a href={manager.instagram}>
                      <InstagramIcon color="#5C5958" />
                    </a>
                  )}
                  {manager.twitter && (
                    <a href={manager.twitter}>
                      <TwitterIcon />
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
          {((artistAccreditations?.items ?? []).length > 0 ||
            (managerAccreditations?.items ?? []).length > 0) && (
            <div className="accreditedPersons">
              <h4>{t('Acreditats')}</h4>
              {artistAccreditations.items.map((artistAccreditation) => {
                return (
                  <Fragment key={artistAccreditation['id']}>
                    {renderAccreditation(artistAccreditation)}
                    <div className="separator" />
                  </Fragment>
                )
              })}
              {managerAccreditations?.items.map((managerAccreditation) => {
                return (
                  <Fragment key={managerAccreditation['id']}>
                    {renderAccreditation(managerAccreditation)}
                    <div className="separator" />
                  </Fragment>
                )
              })}
            </div>
          )}
        </>
      ) : (
        <div>{t('no_accreditations_assigned', { artistName: artistData?.title ?? '-' })}</div>
      )}
    </div>
  )
}
