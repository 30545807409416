import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { requiredError } from 'Components/Forms/FormErrors'
import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import { ModalInfo } from 'NewVersion/components/UI/Modals/Modal'
import { useEdition } from 'hooks/useEdition'
import { AFIRMATIVE_VALUE } from 'utils/constants'
import { ENS_TYPES_OBJ } from 'utils/entityUtils'
import { get } from 'utils/objectUtils'
import { getCurrentJWToken } from 'utils/utils'
import EditComponent from '../Fields/EditComponent'
import { InputAcceptRegulation } from '../Fields/InputAcceptRegulation'

import { CloseOutlined } from '@mui/icons-material'
import { Box } from '@mui/system'
import { AlertComponent } from 'Components/HelperComponents'
import { AddProfessionalsIconOutline } from 'NewVersion/icons/AddProfessionalsIconOutline'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import 'NewVersion/styles/Form.scss'
import { useGuillotina } from 'hooks/useGuillotina'
import { ACCREDITATION_TYPES_OBJ } from 'utils/accreditationUtils'
import { ACCREDITATIONS_SIZES_IMAGE } from 'utils/constants'
import Button from '../UI/Buttons/Button'

export const AddAccreditationsForm = ({ ensType, submitAction, entity, loadingEntity }) => {
  const ensTypeObj = ENS_TYPES_OBJ[ensType]
  const { edition, loading, currentEdition } = useEdition()

  const accreditationTypeObj = ACCREDITATION_TYPES_OBJ[ensType]
    ? ACCREDITATION_TYPES_OBJ[ensType]
    : ACCREDITATION_TYPES_OBJ['professional']

  const {
    data: accreditationSchemaData = {},
    isLoading: accreditationSchemaLoading,
    error: accreditationSchemaError,
  } = useGuillotina({
    path: `@types/${accreditationTypeObj?.interface}`,
  })

  if (loading || accreditationSchemaLoading || loadingEntity) {
    return <LoadingComponent />
  }

  return (
    <AddAccreditationsFormContent
      currentEdition={currentEdition}
      ensTypeObj={ensTypeObj}
      edition={edition}
      accreditationSchema={{
        data: accreditationSchemaData,
        error: accreditationSchemaError,
        loading: accreditationSchemaLoading,
      }}
      submitAction={submitAction}
      accreditationTypeObj={accreditationTypeObj}
      dataEntity={entity}
    />
  )
}

const AddAccreditationsFormContent = ({
  edition,
  accreditationSchema,
  submitAction,
  ensTypeObj,
  currentEdition,
  accreditationTypeObj,
  readOnlyForm = false,
  dataEntity,
}) => {
  const [totalAccreditationsToAdd, setTotalAccreditationsToAdd] = useState(1)
  const { t } = useTranslation()
  const [submitError, setSubmitError] = React.useState({
    hasError: false,
    message: '',
  })

  const [submitModal, setSubmitModal] = useState(false)

  const handleAccreditationError = (error) => {
    if (error) {
      if (get(error, 'code') === 'invalid-email') {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('invalid_email')}`,
        })
      } else {
        setSubmitError({
          hasError: true,
          message: `Error: ${t('error_create_or_update_ens')}`,
        })
      }
    } else {
      setSubmitError({
        type: 'danger',
        message: `Error: ${t('error_generic')}`,
      })
    }
    window.scrollTo(0, 0)
  }

  const handleAccreditationImageError = () => {
    setSubmitError({
      hasError: true,
      message: `Error: ${t('error_uploading_image')}`,
    })
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let finishSuccessfully = true
      setSubmitting(true)
      const valuesEntity = {}
      const token = getCurrentJWToken()

      Object.keys(values).forEach((valueKey) => {
        if (valueKey.indexOf('entity_') === 0) {
          valuesEntity[valueKey.replace('entity_', '')] = values[valueKey]
        }
      })

      const dataAccreditations = []
      const keysAccreditationForm = [...Array(totalAccreditationsToAdd).keys()]
      keysAccreditationForm.forEach((index) => {
        const valuesAccreditation = {}
        Object.keys(values).forEach((valueKey) => {
          if (valueKey.indexOf(`accreditation_${index}_`) === 0) {
            valuesAccreditation[valueKey.replace(`accreditation_${index}_`, '')] = values[valueKey]
          }
        })

        const dataAccreditation = {}
        dataAccreditation['values'] = Object.assign({}, valuesAccreditation, {
          '@type': accreditationTypeObj.interface,
          tarifa: 'estandard',
          idioma: dataEntity.idioma,
        })

        if (dataAccreditation['values']['primer_cop_fira'] === AFIRMATIVE_VALUE) {
          dataAccreditation['values']['primer_cop_fira'] = true
        } else {
          dataAccreditation['values']['primer_cop_fira'] = false
        }

        if (dataAccreditation['values']['imatge'] !== null) {
          dataAccreditation['imatge'] = dataAccreditation['values']['imatge']
          delete dataAccreditation['values']['imatge']
        }

        dataAccreditations.push(dataAccreditation)
      })

      for (let i = 0; i < dataAccreditations.length; i++) {
        const dataToSend = dataAccreditations[i]
        let path = `${edition}/${dataEntity['@name']}`
        try {
          const response = await fetchGuillotina({
            path: path,
            method: 'POST',
            data: dataToSend['values'],
            token: token,
          })
          path = `${path}/${response['@name']}`

          if (dataToSend['imatge'] && dataToSend['imatge'] instanceof File) {
            let filename = undefined
            if (dataToSend['imatge'].filename) {
              filename = dataToSend['imatge'].filename
            } else if (dataToSend['imatge'].name) {
              filename = dataToSend['imatge'].name
            }
            const fileHeaders = {
              'X-UPLOAD-EXTENSION': dataToSend['imatge'].type.split('/')[1],
              // 'X-UPLOAD-SIZE': dataToSend['imatge'].size,
              'X-UPLOAD-FILENAME-B64': btoa(unescape(encodeURIComponent(filename))),
              'CONTENT-TYPE': dataToSend['imatge'].type,
            }
            await fetchGuillotina({
              path: `${path}/@upload/imatge`,
              method: 'PATCH',
              token: token,
              headers: {
                ...fileHeaders,
              },
              data: dataToSend['imatge'],
              notStringify: true,
            })
              .then(async () => {
                for (let i = 0; i < ACCREDITATIONS_SIZES_IMAGE.length; i++) {
                  await fetchGuillotina({
                    path: `${path}/@images/imatge/${ACCREDITATIONS_SIZES_IMAGE[i]}`,
                    method: 'PATCH',
                    token: token,
                    headers: {
                      ...fileHeaders,
                    },
                  })
                }
              })
              .catch((error) => {
                if (error) handleAccreditationImageError()
                return
              })
          }
        } catch (error) {
          finishSuccessfully = false
          handleAccreditationError(error)
        }
      }

      if (submitAction && finishSuccessfully) {
        submitAction(dataEntity['@name'])
      }
      setSubmitting(false)
    } catch (error) {
      console.log(error)
    }
  }

  const getInitialValues = React.useMemo(() => {
    const result = {}

    const keysAccreditationForm = [...Array(totalAccreditationsToAdd).keys()]
    keysAccreditationForm.forEach((index) => {
      accreditationTypeObj?.['requiredFields'].forEach((item) => {
        result[`accreditation_${index}_${item}`] = undefined
      })
    })

    result['declaracio_acceptar'] = false
    return result
  }, [ensTypeObj])

  const formik = useFormik({
    initialValues: getInitialValues,
    validateOnChange: false,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {}

      const keysAccreditationForm = [...Array(totalAccreditationsToAdd).keys()]
      keysAccreditationForm.forEach((index) => {
        accreditationTypeObj['requiredFields'].forEach((key) => {
          if (key !== 'declaracio_acceptar') {
            const keyForm = `accreditation_${index}_${key}`
            if (!(keyForm in values) || !values[keyForm]) {
              errors[keyForm] = requiredError()
            }
          }
        })
        const keyForm = `accreditation_${index}_correu`
        if (values[keyForm] && !values[keyForm].match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
          errors[keyForm] = t('invalid_email')
        }
      })
      if (!('declaracio_acceptar' in values) || !values['declaracio_acceptar']) {
        errors['declaracio_acceptar'] = requiredError()
      }
      return errors
    },
  })

  const renderInputAcceptRegulation = (key) => {
    return (
      <InputAcceptRegulation
        onChange={(e) => {
          formik.setFieldValue(key, e.target.checked)
          formik.validateForm(Object.assign({}, formik.values, { key: e.target.checked }))
        }}
        onBlur={formik.onBlur}
        checked={!!formik.values[key]}
        error={formik.touched[key] && formik.errors[key]}
        readOnly={readOnlyForm}
        name={key}
      />
    )
  }

  const resetForm = () => {
    const result = formik.values
    accreditationTypeObj['requiredFields'].forEach((item) => {
      result[`accreditation_${totalAccreditationsToAdd}_${item}`] = undefined
    })
    formik.resetForm({
      values: result,
    })
    setTotalAccreditationsToAdd(totalAccreditationsToAdd + 1)
  }

  return (
    <>
      {submitError.hasError && submitError.message && (
        <AlertComponent type="danger"> {submitError.message}</AlertComponent>
      )}
      <form
        className="Form"
        onSubmit={(values) => {
          formik.handleSubmit(values)
        }}
        data-test="formTest"
      >
        {ensTypeObj && (
          <h4 className="header-add-accreditations">
            {ensTypeObj?.['interface'] === ENS_TYPES_OBJ.premsa.ens_type_name
              ? t('press_registrations')
              : t('professional_registrations')}
          </h4>
        )}
        <p>
          {ensTypeObj?.['interface'] === ENS_TYPES_OBJ.premsa.ens_type_name
            ? t('press_registrations_subtitle')
            : t('professional_registrations_subtitle')}
        </p>
        {[...Array(totalAccreditationsToAdd).keys()].map((index) => {
          return (
            <div key={`accreditationForm_${index}`} className="acreditacio">
              <div>
                <h5>
                  {t('acreditacio')}&nbsp;{(dataEntity?.total_acreditats || 0) + index + 1}
                </h5>
                {index > 0 && (
                  <Button
                    type="button"
                    variant="outlined"
                    icon={<CloseOutlined />}
                    onClick={() => {
                      const result = formik.values
                      accreditationTypeObj['requiredFields'].forEach((item) => {
                        delete result[`accreditation_${totalAccreditationsToAdd - 1}_${item}`]
                      })
                      formik.resetForm({
                        values: result,
                      })
                      setTotalAccreditationsToAdd(totalAccreditationsToAdd - 1)
                    }}
                  >
                    {t('delete')}
                  </Button>
                )}
              </div>

              {accreditationSchema &&
                accreditationSchema.data &&
                !accreditationSchema.loading &&
                accreditationTypeObj?.['orderFieldsForm']
                  .filter((item) => item.title !== 'declaracio_fieldset')
                  .map((item, indexItem) => {
                    return (
                      <div
                        className="fragment"
                        key={`accreditationFormSection_${item['title']}_${indexItem}`}
                      >
                        {item['fields'].map((key) => {
                          if (get(accreditationSchema, `data.properties[${key}]`, null)) {
                            const value = accreditationSchema.data.properties[key]
                            const keyForm = `accreditation_${index}_${key}`
                            return (
                              <EditComponent
                                key={keyForm}
                                name={keyForm}
                                label={t(key)}
                                schema={value}
                                description={value.description ? t(`${key}_description`) : null}
                                value={formik.values[keyForm]}
                                required={(accreditationTypeObj['requiredFields'] ?? []).includes(
                                  key
                                )}
                                onBlur={() => {
                                  formik.setFieldTouched(keyForm)
                                }}
                                onChange={(ev) => {
                                  formik.setFieldValue(keyForm, ev)
                                  formik.validateForm()
                                }}
                                error={formik.touched[keyForm] && formik.errors[keyForm]}
                                dataTest={`inputFieldTest_${keyForm}`}
                              />
                            )
                          }
                        })}
                      </div>
                    )
                  })}
            </div>
          )
        })}
        <div>
          <Button
            type={'button'}
            dataTest={'add_more_accreditationsbtn'}
            onClick={() => {
              const maxAccreditationNumber =
                currentEdition.getConfigData()[ensTypeObj.totalAccreditationAttribute]
              if (
                totalAccreditationsToAdd + dataEntity.total_acreditats >=
                maxAccreditationNumber
              ) {
                setSubmitModal(true)
              } else {
                resetForm()
              }
            }}
            icon={<AddProfessionalsIconOutline fill={'white'} />}
          >
            {t('add_more_accreditations')}
          </Button>
        </div>

        <Box mt={5} mb={3}>
          <h5>{t('declaracio_fieldset')}</h5>
          {renderInputAcceptRegulation('declaracio_acceptar')}
        </Box>
        <div>
          {formik.isSubmitting && <LoadingComponent />}
          {!readOnlyForm && !formik.isSubmitting && (
            <div className="footer-buttons">
              <Button type="submit" data-test="btnSubmitFormTest" disabled={formik.isSubmitting}>
                {t('save')}
              </Button>
            </div>
          )}
        </div>
      </form>
      <ModalInfo
        open={submitModal}
        setOpen={setSubmitModal}
        text={<Trans i18nKey="alert_professional_acreditat" />}
        icon={<AddProfessionalsIconOutline fill={'#273BF3'} />}
        handleOk={resetForm}
        handleCancel={() => setSubmitModal(false)}
        textOk={t('alert_professional_acreditat_ok')}
        textCancel={t('alert_professional_acreditat_cancel')}
      ></ModalInfo>
    </>
  )
}
