import { Dialog } from '@mui/material'
import { Box } from '@mui/system'
import { LoadingComponent } from 'Components/HelperComponents'
import { ArrowBackIcon } from 'NewVersion/icons/ArrowBackIcon'
import { CrossIcon } from 'NewVersion/icons/CrossIcon'
import { EmailIcon } from 'NewVersion/icons/EmailIcon'
import { FacebookIcon } from 'NewVersion/icons/FacebookIcon'
import { FavouriteIcon } from 'NewVersion/icons/FavouriteIcon'
import { InstagramIcon } from 'NewVersion/icons/InstagramIcon'
import { LinkIcon } from 'NewVersion/icons/LinkIcon'
import LinkedinIcon from 'NewVersion/icons/LinkedinIcon'
import { TwitterIcon } from 'NewVersion/icons/TwitterIcon'
import YoutubeIcon from 'NewVersion/icons/YoutubeIcon'
import { fetchGuillotina } from 'NewVersion/services/guillotina'
import 'NewVersion/styles/ProfessionalDialog.scss'
import { useEdition } from 'hooks/useEdition'
import { useFavourites } from 'hooks/useFavourites'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { downloadImageGuillotina } from 'utils/apiCalls'
import { cleanObjectIds, getCurrentJWToken } from 'utils/utils'

const ProfessionalEntityDialogComponent = ({ uuid, onClose, onClick, onBack }) => {
  const { t } = useTranslation()
  const { edition } = useEdition()
  const { favouiteIds, addFavourite, removeFavourite } = useFavourites()
  const [blobImg, setBlobImg] = useState(null)
  const [loading, setLoading] = useState(true)
  const [professionalEntity, setProfessionalEntity] = useState(null)
  const token = getCurrentJWToken()
  const isFavourite = favouiteIds && uuid && favouiteIds.includes(uuid)

  const getProfessionalEntityInfo = async () => {
    const response = await fetchGuillotina({
      path: `${edition}/@search?uuid=${uuid}`,
      token: token,
    })
    if (response.items.length > 0) {
      const responseData = await fetchGuillotina({
        path: response.items[0]['path'],
        token: token,
      })
      if (responseData && responseData.imatge) {
        await getImg(responseData)
      } else {
        setBlobImg(null)
      }
      setProfessionalEntity(responseData)
      setLoading(false)
    } else {
      setBlobImg(null)
    }
  }

  useEffect(() => {
    if (uuid) {
      setLoading(true)
      getProfessionalEntityInfo()
    } else {
      setLoading(false)
      setProfessionalEntity(null)
    }
  }, [uuid])

  const getImg = async (responseData) => {
    try {
      const image = await downloadImageGuillotina(
        cleanObjectIds(responseData['@id']),
        'imatge/preview',
        'images'
      )
      if (image) {
        setBlobImg(image)
      }
    } catch (error) {
      console.error(error)
    }
  }

  if (uuid === null) {
    return <></>
  }

  const renderInfo = (title, value) => (
    <Box className="professional-dialog-content-info">
      <p className="body-2-bold">{title}</p>
      {value}
    </Box>
  )

  const getSocialLinks = () => {
    return (
      <div className="professional-social-links">
        {professionalEntity.facebook && (
          <a target="_blank" href={professionalEntity.facebook} rel="noreferrer">
            <FacebookIcon />
          </a>
        )}
        {professionalEntity.instagram && (
          <a target="_blank" href={professionalEntity.instagram} rel="noreferrer">
            <InstagramIcon />
          </a>
        )}
        {professionalEntity.twitter && (
          <a target="_blank" href={professionalEntity.twitter} rel="noreferrer">
            <TwitterIcon />
          </a>
        )}
        {professionalEntity.youtube && (
          <a target="_blank" href={professionalEntity.youtube} rel="noreferrer">
            <YoutubeIcon />
          </a>
        )}
        {professionalEntity.linkedin && (
          <a target="_blank" href={professionalEntity.linkedin} rel="noreferrer">
            <LinkedinIcon />
          </a>
        )}
      </div>
    )
  }

  const handleClose = () => {
    onClose()
    setLoading(true)
    setBlobImg(null)
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={Boolean(uuid)}
        onClose={() => handleClose()}
        classes={{ paper: 'professional-dialog' }}
      >
        <Box
          className="professional-dialog-back-icon"
          onClick={() => onBack()}
          data-test="backDialog-icon"
        >
          <ArrowBackIcon />
        </Box>
        <Box
          className={`professional-dialog-imatge ${
            professionalEntity &&
            (professionalEntity['@type'] === 'AcreditacioProfessional' ||
              professionalEntity['@type'] === 'AcreditacioPremsa')
              ? 'image'
              : ''
          }`}
          data-test="professional-dialog-imatge"
          style={blobImg ? { backgroundImage: `url('${blobImg}')` } : {}}
        />
        {loading || !professionalEntity ? (
          <Box
            className={'professional-dialog-content professional-dialog-loading'}
            data-test="professional-dialog-content"
          >
            <LoadingComponent />
          </Box>
        ) : (
          <Box className={'professional-dialog-content'}>
            <Box
              className="professional-dialog-close-icon"
              onClick={() => handleClose()}
              data-test="closeDialog-icon"
            >
              <CrossIcon />
            </Box>
            <Box display={'flex'} justifyContent={'space-between'}>
              <h4 className="hide-sm" data-test="dialog-title">
                {professionalEntity.nom
                  ? `${professionalEntity.nom} ${professionalEntity.cognoms}`
                  : professionalEntity.title}
              </h4>
              <Box className={'professional-dialog-content-buttons'}>
                {professionalEntity.web && (
                  <a href={`${professionalEntity.web}`} rel="noreferrer">
                    <LinkIcon width="24px" height="24px" />
                  </a>
                )}
                {professionalEntity.correu && (
                  <a href={`mailto:${professionalEntity.correu}`} rel="noreferrer">
                    <EmailIcon width="24px" height="24px" />
                  </a>
                )}
                <Box
                  onClick={() => {
                    if (isFavourite) {
                      removeFavourite(professionalEntity['@uid'])
                    } else {
                      addFavourite(professionalEntity['@uid'])
                    }
                  }}
                >
                  <FavouriteIcon width="24px" height="24px" outline={!isFavourite} />
                </Box>
              </Box>
            </Box>
            <h4 className="display-sm">{`${professionalEntity.nom} ${professionalEntity.cognoms}`}</h4>
            <Box className="divider" mt={'4px'} mb={2} />
            {professionalEntity.ens_title &&
              renderInfo(
                t('Entitat'),
                <Box
                  className="cursor-pointer"
                  onClick={() => {
                    if (professionalEntity.parent && professionalEntity.parent['@uid']) {
                      setBlobImg(null)
                      onClick(professionalEntity.parent['@uid'])
                    }
                  }}
                >
                  {professionalEntity.ens_title}
                </Box>
              )}
            {professionalEntity.carrec &&
              renderInfo(t('Càrrec'), <p>{professionalEntity.carrec}</p>)}
            {(professionalEntity.codi_postal || professionalEntity.pais) &&
              renderInfo(
                t('procedencia'),
                <p>{`${professionalEntity.comarca ? `${t(professionalEntity.comarca)}, ` : ''}${
                  professionalEntity.comunitat_autonoma
                    ? `${t(professionalEntity.comunitat_autonoma)}, `
                    : ''
                }${t(professionalEntity.pais)}`}</p>
              )}
            <Box className="split-content">
              {professionalEntity.telefon &&
                renderInfo(
                  t('telefon'),
                  <a href={'tel:${telefon}'}>{professionalEntity.telefon}</a>
                )}
              {professionalEntity.correu &&
                renderInfo(
                  t('correu'),
                  <a href={`mailto:${professionalEntity.correu}`}>{professionalEntity.correu}</a>
                )}
            </Box>
            {professionalEntity.items &&
              professionalEntity.items.length > 0 &&
              renderInfo(
                t('Acreditats'),
                <>
                  {professionalEntity.items
                    .filter(
                      (item) =>
                        item['@type'] === 'AcreditacioProfessional' ||
                        item['@type'] === 'AcreditacioPremsa'
                    )
                    .map((item) => (
                      <Box
                        onClick={() => {
                          if (item['@uid']) {
                            setBlobImg(null)
                            onClick(item['@uid'])
                          }
                        }}
                        key={`others-${item['@uid']}`}
                        className="cursor-pointer"
                        data-test="accrBox"
                      >
                        {item.title}
                      </Box>
                    ))}
                </>
              )}
            <Box className="divider" mt={1} my={2} />
            {getSocialLinks()}
          </Box>
        )}
      </Dialog>
    </React.Fragment>
  )
}

export const ProfessionalEntityDialog = React.memo(ProfessionalEntityDialogComponent)
