import { fetchGuillotina } from 'NewVersion/services/guillotina'
import { EditionInstance } from 'models'
import React, { useEffect, useRef, useState } from 'react'
import { get } from 'utils/objectUtils'
import { getCurrentJWToken } from 'utils/utils'

const Context = React.createContext({})

export const ALL_EDITIONS = ['2021', '2022', '2023', '2024']

const getCurrentEditionLocalStorage = () => {
  if (localStorage.edition && localStorage.edition.length > 0) {
    return JSON.parse(localStorage.edition)[0]
  }

  return '2024'
}

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef()
  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current
}

export function EditionContextProvider({ children }) {
  const isAuthenticated = localStorage.isAuthenthicated
  const token = getCurrentJWToken()
  const [edition, setEdition] = useState(isAuthenticated ? getCurrentEditionLocalStorage() : null)
  const [editionsObj, setEditionsObj] = useState({})
  const [currentEdition, setCurrentEdition] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const editionPrev = usePrevious(edition)

  useEffect(() => {
    ;(async () => {
      if (edition && !(edition in editionsObj) && !loading) {
        setLoading(true)
        let response = null
        try {
          response = await fetchGuillotina({ path: edition, token: token })
        } catch (err) {
          response = err.response
        }
        let responseCanIDo = null
        try {
          responseCanIDo = await fetchGuillotina({
            path: `${edition}/@canido?permissions=fm.PresentarProposta`,
            token: token,
          })
        } catch (err) {
          responseCanIDo = err.response
        }
        let newEditionsObj = {}
        if (response && responseCanIDo) {
          newEditionsObj = {
            ...editionsObj,
            [edition]: {
              data: response,
              userPerms: responseCanIDo,
            },
          }
        } else {
          newEditionsObj = {
            ...editionsObj,
            [edition]: {
              data: {},
              userPerms: {},
            },
          }
        }

        const editionData = get(newEditionsObj, `${edition}.data`, undefined)
        const editionUserPerms = get(newEditionsObj, `${edition}.userPerms`, undefined)

        if (editionData && editionUserPerms) {
          setCurrentEdition(new EditionInstance(edition, editionData, editionUserPerms))
        }
        setEditionsObj(newEditionsObj)
        setLoading(false)
      } else if (edition in editionsObj && editionPrev !== edition) {
        const editionData = get(editionsObj, `${edition}.data`, undefined)
        const editionUserPerms = get(editionsObj, `${edition}.userPerms`, undefined)

        if (editionData && editionUserPerms) {
          setCurrentEdition(new EditionInstance(edition, editionData, editionUserPerms))
        }
      }
    })()
  }, [setEditionsObj, loading, edition, editionsObj, currentEdition, editionPrev])

  const reset = () => {
    setEdition(null)
    setCurrentEdition(undefined)
    setEditionsObj({})
  }

  const setLastEdition = () => {
    setEdition(getCurrentEditionLocalStorage())
    setEditionsObj({})
  }

  const setNewEdition = (e) => {
    if (e !== edition) {
      setCurrentEdition(undefined)
      setEdition(e)
    }
  }

  return (
    <Context.Provider
      value={{
        edition,
        setNewEdition,
        editionsObj,
        loading,
        reset,
        setLastEdition,
        currentEdition,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Context
