import { BurgerMenuIcon } from 'NewVersion/icons/BurgerMenuIcon'
import { CrossIcon } from 'NewVersion/icons/CrossIcon'
import { LineIcon } from 'NewVersion/icons/LineIcon'
import LogoIcon from 'NewVersion/icons/Logo'
import 'NewVersion/styles/Header.scss'
import { useEdition } from 'hooks/useEdition'
import { useTranslation } from 'react-i18next'
import { LanguageButtonsSelector } from '../UI/Buttons/LanguageButtonsSelector'
import { EditionSelector } from '../UI/Selector/EditionSelector'
import { UserOptionsSelector } from '../UI/Selector/UserOptionsSelector'

function Header({ mobileHeaderOpen, setMobileHeaderOpen }) {
  const { t } = useTranslation()
  const isAuthenticated = localStorage.isAuthenthicated
  const { edition } = useEdition()

  const handleMobileHeader = () => {
    setMobileHeaderOpen(!mobileHeaderOpen)
  }

  return (
    <nav className="navbar-menu">
      <div className="logo">
        <div>
          <a href="/home" aria-label="Go home">
            <LogoIcon fill={'white'} />
          </a>
        </div>
        <p className="area-pro">Àrea PRO</p>
        <div>
          <LineIcon />
        </div>{' '}
        <p className="fira-med">Fira Mediterrània de Manresa</p>
      </div>
      <div className={'right-bar'}>
        {isAuthenticated && <EditionSelector label={`${t('edition')} ${edition}`} header />}
        <div className="user-options">
          <LanguageButtonsSelector />
          {isAuthenticated && <UserOptionsSelector />}
        </div>
        {isAuthenticated && (
          <div className="mobile-menu" onClick={() => handleMobileHeader()}>
            {mobileHeaderOpen ? <CrossIcon /> : <BurgerMenuIcon />}
          </div>
        )}
      </div>
    </nav>
  )
}
export default Header
