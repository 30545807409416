import axios from 'axios'
import jwt from 'jwt-decode'
import * as React from 'react'
import { Navigate, Route, Routes as RoutesReact, useLocation, useNavigate } from 'react-router-dom'
import usePushNotifications from '../usePushNotifications'

import { rootInitialState, rootReducer } from '../Reducers/root'
import { useInterval } from '../useInterval'

import LoadingComponent from 'Components/HelperComponents/LoadingComponent'
import Login from 'NewVersion/pages/Login'
import NewPasswordPage from 'NewVersion/pages/NewPasswordPage'
import RecoveryPassword from 'NewVersion/pages/RecoveryPassword'
import Register from 'NewVersion/pages/Register'
import NotAllowPage from 'Pages/NotAllowPage'
import NotFound from 'Pages/NotFound'
import { useEdition } from 'hooks/useEdition'
import { getCurrentJWToken } from '../utils/utils'
import { PathToPageMap } from './PathToPageMap'
import * as path from './Paths'

export const Routes = () => {
  const location = useLocation()
  const { currentEdition } = useEdition()
  const navigate = useNavigate()
  const [canAccesInfo, setCanAccesInfo] = React.useState({
    data: undefined,
    loading: false,
  })

  const [, dispatch] = React.useReducer(rootReducer, rootInitialState)
  const { onClickSusbribeToPushNotification } = usePushNotifications()
  const isSupported = () =>
    'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window

  React.useEffect(() => {
    // IF granted, localstorage.accepteNotif undefined i localstorage.denied undefined, envia subs
    try {
      if (isSupported) {
        if (
          localStorage.acceptNotifications === undefined &&
          localStorage.deniedNotifications === undefined &&
          Notification.permission === 'granted'
        ) {
          // notificacions acceptades, no el tenim subscrit, el subscribim
          localStorage.setItem('acceptNotifications', false)
          onClickSusbribeToPushNotification()
        }
      }
    } catch (error) {
      console.error(error)
      // expected output: ReferenceError: nonExistentFunction is not defined
      // Note - error messages will vary depending on browser
    }
  }, [location, onClickSusbribeToPushNotification])

  useInterval(() => {
    if (getCurrentJWToken()) {
      const token = jwt(getCurrentJWToken())
      const d = new Date(0)
      const now = new Date()
      d.setUTCSeconds(token.exp)
      const nextTimeout = Math.round((d - now) / 1000)
      // petit que, actualitzara mentre hi haigui activitat (e: moures entre pagines)
      // callback de timeout es problematic i si tanquen la app s'en va a la merda
      if (nextTimeout < 0) {
        dispatch({
          type: 'LOGOUT',
        })
        navigate(path.LOGIN_PATH)
      }
      if (nextTimeout < 10000) {
        // RENEW TOKEN 3 HOURS BEFORE EXPIRATION
        const token = getCurrentJWToken()
        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-9',
          Authorization: `Bearer ${token}`,
        }
        axios
          .post(
            'https://oldplone.firamediterrania.cat/mediterrania2019/@login-renew',
            {},
            { headers }
          )
          .then(function (response) {
            const token_val = response.data.token
            console.log('Token renewed')
            localStorage.setItem('token', token_val)
          })
          .catch(function (response) {
            // handle error
            console.log('error')
          })
      }
    }
  }, 60000) // Cada 5 minuts hauria de ser 300000

  React.useEffect(() => {
    if (currentEdition === undefined && canAccesInfo.data !== undefined) {
      setCanAccesInfo({ data: undefined, loading: false })
    }
    async function load() {
      setCanAccesInfo((prev) => ({ ...prev, loading: true }))
      try {
        const result = await currentEdition.canAccesToInfo()
        setCanAccesInfo({ data: result, loading: false })
      } catch (err) {
        setCanAccesInfo({ data: false, loading: false })
      }
    }

    if (currentEdition && canAccesInfo.data === undefined && !canAccesInfo.loading) {
      load()
    }
  }, [canAccesInfo, setCanAccesInfo, currentEdition])

  if ((!currentEdition || canAccesInfo.loading) && localStorage.isAuthenthicated) {
    return <LoadingComponent />
  }

  if (!canAccesInfo.data && localStorage.isAuthenthicated) {
    return <NotAllowPage />
  }

  if (localStorage.isAuthenthicated === 'true') {
    return (
      <RoutesReact>
        {currentEdition &&
          currentEdition.getConfigRoutes().map((route) => {
            if (route.redirect) {
              return (
                <Route
                  key={`${route.from}-${route.to}`}
                  path={route.from}
                  element={<Navigate replace to={route.to} />}
                />
              )
            }
            return (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.path}
                element={PathToPageMap[route.path]}
              />
            )
          })}
        <Route path="*" element={<NotFound />} />
      </RoutesReact>
    )
  } else {
    // We check if route had something
    if (location.pathname !== 'login') {
      localStorage.setItem('reRoute', location.pathname)
    }
    return (
      <RoutesReact>
        <Route path={path.LOGIN_PATH} element={<Login />} />
        <Route path={path.REGISTER_PATH} element={<Register />} />
        <Route path={path.RECOVERY_PATH} element={<RecoveryPassword />} />
        <Route path={path.NEW_PASSWORD_PATH} element={<NewPasswordPage />} />
        <Route path="/" element={<Navigate replace to={path.LOGIN_PATH} />} />
      </RoutesReact>
    )
  }
}
